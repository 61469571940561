<template>
  <div
    v-if="relation"
    :class="$style.root"
  >
    {{ $t('shared.relatedTo') }}
    <i18nLink
      :to="{ name: 'biennials-slug-index-about', params: { slug: relation?.slug } }"
      :class="$style.link"
    >
      {{ relation?.title }}
    </i18nLink>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  page: object
}>()

const relation = props?.page?.biennialEntry?.data?.attributes
</script>

<style module>
.root {
  user-select: none;
}

.link {
  text-decoration-line: underline;
}

.link:hover {
  text-decoration-line: none;
}

.link:focus-within {
  text-decoration-line: none;
}
</style>
